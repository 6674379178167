/* eslint-disable max-len */
import { React } from 'react';
import Home from './pages/home/home.jsx';
import Altareclamos from './pages/alta-reclamos/Altareclamos';
import Consultareclamo from './pages/consultareclamo/Consultareclamo';
import Datosreclamo from './pages/datos-reclamo/Datosreclamo';
import Detallesreclamo from './pages/detalles-reclamo/Detallesreclamo';
import { Route, Routes } from 'react-router-dom';
import NotFound from './pages/notFound/notFound';
import './App.css'

/**
 * @return {ReactComponentElement}
 */
function App() {
  return (
    <section>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reclamos/alta/:step" element={<Altareclamos />} />
        <Route path="/reclamos/consulta" element={<Consultareclamo />} />
        <Route path="/reclamos/deunapersona" element={<Datosreclamo />} />
        <Route path="/reclamos/:id/:anio" element={<Detallesreclamo />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </section>
  );
}

export default App;
