import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pregunta from './Pregunta';
import useTicketStore from '../stores/ticket.store';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

/**
 * Recibe la prestacion y muestra el cuestionario
 * El cuestionario de la prestacion puede tener una o varias preguntas, de diferentes tipos, mas una nota y si debe ser obligatoria o no
 * Para el caso de los archivos, se agregan a los adjuntos de la prestacion
 * Value es un array de objetos [{code, answer}, ...]
 * 
 * @param {*} value Valores actuales del cuestionario
 * @param {*} prestacion Prestación en proceso
 * @param {*} onChange Evento cuando se registra una respuesta
 * @return {React.ReactFragment} 
 */
export const Cuestionario = ({
  value,
  prestacion,
  onChange,
  namespace,
  options: { setMessageSnackbar },
  className,
  ...rest
}) => {
  // value son todas las prestaciones
  // prestacion es la prestacion seleccionada
  const [loading, setLoading] = useState(false);
  const {direccion, questionnaire, setQuestionnaire} = useTicketStore();
  
  // Recupera el cuestionario de la prestacion
  const load = async (prest) => {
    try {
      if (loading) return;
      setLoading(true);
      let url = new URL(`${api}/prestacionCuestionario/${prest.code}`);
      fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((r) => r.json())
        .then((quest) => {
          if (prest?.code === '2101' && quest?.length > 0) { // Arbolado
            let respuesta = value.find((v) => v.code == 404); // Barrio
            if (!respuesta) {
              value.push({ code: 404, answer: direccion?.zonaurbana=='N/A' ? 'Zona urbana' : direccion?.zonaurbana || '' });
            }
            // Si es zona urbana, solo se puede elegir Vereda
            if (direccion?.zonaurbana=='N/A') {
              respuesta = value.find((v) => v.code == 405); // Ubicacion -> Vereda (unicamente)
              if (!respuesta) {
                value.push({ code: 405, answer: 'Vereda' });
              }
              const question = quest.find((q) => q.code == 405);
              if(question) question.values = 'Vereda';
            }
            const question = quest.find((q) => q.code == 404);
            if(question) question.disabled = true;
          }
          setQuestionnaire(quest);
          setLoading(false);
        })
        .catch(() => {
          setMessageSnackbar('Error: no se puede recuperar el cuestionario');
          setLoading(false);
        })
    } catch (err) {
      setMessageSnackbar(err.message);
      setLoading(false);
    }
  };

  // Si el cuestionario no ha sido cargado, lo traigo del backend
  useEffect(() => {
    if (questionnaire?.length === 0) {
      load(prestacion);
    }
  }, []);

  // Cada pregunta que se responde, se registra en el value con su key. Si cambia su valor, se actualiza
  const handlePregunta = (newValue) => {
    const respuesta = value.find((v) => v.code == newValue.code);
    if (respuesta) {
      respuesta.answer = newValue.answer;
    } else {
      value.push({ code: newValue.code, answer: newValue.answer });
    }
    onChange(value);
  };

  
  // Por defecto el cuestionrio se expande al 100% del ancho
  // Cada pregunta se despliega como un elemento del flex
  return (
    <div className={"flex w-full flex-wrap gap-5 " + (className ? className : '')} {...rest}>
      {questionnaire?.length > 0 &&
        questionnaire
          .sort((a, b) => a.order - b.order)
          .map((p, ix) => (
              <Pregunta
                key={ix}
                pregunta={p}
                onChange={handlePregunta}
                value={value.find((v) => v.code == p.code)?.answer || ''}
                namespace={namespace}
                options={{ setMessageSnackbar }}
              />))
      }
    </div>);
}

Cuestionario.propTypes = {
  value: PropTypes.array.isRequired,
  prestacion: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  namespace: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export const validateCuestionario = (prestacion, answers, cuestionario) => {  
  const errores = [];
  cuestionario.forEach((p) => {
    const answer = answers.find((a) => a.code == p.code);
    if(p.mandatory && 
      (!answer || 
      (typeof(answer) === 'object' && answer.answer === null) ||
      (typeof(answer) === 'object' && answer.answer?.length === 0) || 
      (typeof(answer) === 'string' && answer.answer === '')) 
    ) {
        errores.push(`${p.question} es obligatorio`);
    }
  })
  return errores;
}

export default Cuestionario;
