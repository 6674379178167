import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const DireccionColectivo = ({direccion, onSetText}) => {
    return (<div style={{ flex: "1 1 calc(100% - 10px)", display: "flex", flexWrap: "wrap", gap: "10px", flewWrap: "wrap" }}>
        <TextField style={{ flex: "0 0 130px", lineHeight: "60px" }}
            variant="outlined"
            label="Linea *"
            value={direccion?.linea}
            onChange={onSetText('linea')}
        />
        <TextField style={{ flex: "0 0 140px", lineHeight: "60px" }}
            variant="outlined"
            label="Interno"
            value={direccion?.interno}
            onChange={onSetText('interno')}
        />
        <TextField style={{ flex: "0 0 280px", lineHeight: "60px" }}
            variant="outlined"
            label="Fecha y hora"
            value={direccion?.fecha_hora}
            onChange={onSetText('fecha_hora')}
        />
    </div>);
};

DireccionColectivo.propTypes = {
    direccion: PropTypes.object.isRequired,
    onSetText: PropTypes.func.isRequired,
}

export default DireccionColectivo;
