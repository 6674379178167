/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, Button } from '@mui/material';
import Reclamo from '../../componentes/Reclamo';
import SlideImage from '../../img/v2/SLIDE.jpg';
import FooterImage from '../../img/v2/FOOTER.jpg';
import useUserStore from '../../stores/user.store';
import Toast from '../../componentes/Toast';
import axios from 'axios';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

const Datosreclamo = () => {
  const [reclamos, setReclamos] = useState([]);
  const [message, setMessageSnackbar] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const token = useUserStore(state => state.token);
  const user = useUserStore(state => state.user);

  const cargaReclamos = () => {
    return new Promise((resolve, reject) => {
      try {
        if (loading) resolve();
        setLoading(true);
        let url = new URL(`${api}/ticket/me?limit=10&page=${page}`);
        axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`,
          }
        }).then((response) => {
          if (response.status == 200) {
            const datos = response.data;
            if (datos.message) {
              throw new Error('no tiene reclamos');
            }
            setReclamos(reclamos.concat(datos.records));
            if (datos.totalPages > page) {
              setPage(page + 1);
            }
            setLoading(false);
            resolve();
          } else {
            setLoading(false);
            const msg = 'Su sesión ha expirado. Debe identificarse nuevamente.';
            setMessageSnackbar(msg);
            reject(new Error(msg));
          }
        }).catch((err) => {
          setMessageSnackbar(err.message);
          setLoading(false)
          reject(err);
        })
      } catch (err) {
        setMessageSnackbar(err.message);
        setLoading(false)
        reject(err);
      }
    });
  }

  useEffect(() => {
    cargaReclamos()
      .catch(() => {
        navigate('/reclamos/consulta');
      });
  }, [page]);

  return (
    <>
      <img src={SlideImage} className="w-full" />

      <div className='ml-5 mr-5'>
        <div>
          <div className="text-2xl mt-5 font-extrabold text-[rgb(11,59,108)]">Consulta de reclamos</div>

          <p>
            Nombre: {user.nombres.value} {user.apellido.value}<br />
            DNI: {user.dni.value}
          </p>

          <div className='flex flex-row flew-wrap'>
            <div className="flex-1 text-4xl mt-5 mb-5 font-extrabold text-[rgb(11,59,108)]">
              LISTADO DE RECLAMOS
            </div>
            <div className='flex-1 mb-5 flex flex-row flex-wrap justify-end items-middle'>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button variant="contained">Ir al Inicio</Button>
              </Link>
            </div>
          </div>
        </div>

        <div>
          {reclamos.length > 0 ?
            reclamos.map((c, index) => (
              <Reclamo
                style={{ marginBottom: '20px' }}
                key={index}
                index={index}
                reclamo={c}
              />)) : null}

          {!loading && reclamos.length == 0 ?
            <h2>No tiene reclamos anteriores</h2> : null}

          {loading ?
            <CircularProgress /> : null}
        </div>

        <div className='mb-5'>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button style={{ background: "#00a6cc" }} variant="contained" className='datosreclamo-cancelar-button'>Ir al Inicio</Button>
          </Link>
        </div>

        <Toast message={message} severity={'error'} />
      </div>
      <img src={FooterImage} className="w-full" />
    </>
  )
}

export default Datosreclamo;