import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import IniciaTuReclamoImage from '../../img/v2/inicia-tu-reclamo.jpg';
import ConsultaElEstadoImage from '../../img/v2/consulta-el-estado.jpg';
import SolicitaUnTurnoImage from '../../img/v2/solicita-un-turno.jpg';
import PropTypes from 'prop-types';
import useTicketStore from '../../stores/ticket.store.js';

export default function Acciones({className}) {
    const reset = useTicketStore(state => state.reset);
    const navigate = useNavigate();

    return (
        <div className={`flex flex-col lg:flex-row flex-wrap gap-5 justify-between w-full items-center ${className}`}>
            <div className="flex-1" onClick={async () => {
                await reset();
                navigate('reclamos/alta/1');
            }}>
                <img src={IniciaTuReclamoImage} className='max-w-48 sm:max-w-72'/>
            </div>

            <Link to="reclamos/consulta" className="flex-1">
                <img src={ConsultaElEstadoImage} className='max-w-48 sm:max-w-72'/>
            </Link>

            <a 
                href="https://appsb.mardelplata.gob.ar/Consultas/nTurnosWeb/Vistas/FrontEnd/TurnosFiltros.aspx" 
                className="flex-1">
                <img src={SolicitaUnTurnoImage} className='max-w-48 sm:max-w-72'/>
            </a>
        </div>
    );
}

Acciones.propTypes = {
    className: PropTypes.string,
}
