import React from "react";
import { FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import BuscadorLugares from './BuscadorLugares';

const DireccionPlaya = ({direccion, onCambiarLugar}) => {
    return (
        <div style={{ flex: "1 1 calc(100% - 10px)", display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    <FormControl variant="outlined" sx={{ flex: "0 0 90%", lineHeight: "60px" }}>
                        <BuscadorLugares
                            value={direccion?.playa || ''}
                            onChange={onCambiarLugar('playa')}
                            error={false}
                            label={'Playa'}
                            type={'PLAYA'}
                        />
                    </FormControl>
                </div>
    );
}

DireccionPlaya.propTypes = {
    direccion: PropTypes.object.isRequired,
    onCambiarLugar: PropTypes.func.isRequired,
}

export default DireccionPlaya;
