import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@mui/material';
import BuscadorLugares from './BuscadorLugares';

const DireccionPlaza = ({ direccion, onCambiarLugar }) => {
    return (
        <div style={{ flex: "1 1 calc(100% - 10px)", display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <FormControl variant="outlined" sx={{ flex: "0 0 90%", lineHeight: "60px" }}>
            <BuscadorLugares
                value={direccion?.plaza || ''}
                onChange={onCambiarLugar('plaza')}
                error={false}
                label={'Plaza'}
                type={'PLAZA'}
            />
        </FormControl>
    </div>
    )
}

DireccionPlaza.propTypes = {
    direccion: PropTypes.object.isRequired,
    onCambiarLugar: PropTypes.func.isRequired    
}

export default DireccionPlaza;
