import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Mapa from './Mapa.jsx';

const colorEstado = {
    ['pendiente']: '#00a6cc',
    ['en curso']: '#00a6cc',
    ['en espera']: '#baba34',
    ['resuelto']: '#afa',
    ['rechazado']: '#f88',
};

const Estado = ({ value, className }) => {
    let color = 'bg-gray-400 text-white';
    switch (value) {
        case 'ABIERTO':
            color = 'bg-green-400 text-white';
            break;
        case 'CERRADO':
            color = 'bg-red-400 text-white';
            break;
        default:
            break;
    }
    return (<div className={className + color}>{value}</div>);
}
Estado.propTypes = {
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
};

const Direccion = ({ value }) => {
    if (value.tipo == 'COLECTIVO' && value.fecha_hora != '') {
        const f = value.fecha_hora;
        value.evento = (new Date(`${f.substr(0, 4)}-${f.substr(4, 2)}-${f.substr(6, 2)} ${f.substr(9, 2)}:${f.substr(11, 2)}:${f.substr(13, 2)}`)).toLocaleString();
    }
    return (
        <div>
            {(value.tipo == 'DOMICILIO' || value.tipo == 'LUMINARIA' || value.tipo == 'SEMAFORO') &&
                <div>
                    {value.alternativa == 'NRO' ?
                        <div>{value.calle_nombre} {value.callenro}</div> :
                        <div className="flex">
                            <div className="flex-[75%]">{value.calle_nombre} y {value.calle_nombre2}</div>
                        </div>
                    }
                    {value.piso && value.piso != '' &&
                        <div className="flex">
                            <div className="flex-[25%] font-bold mr-5">Piso</div>
                            <div className="flex-[75%]">{value.piso} {value.dpto}</div>
                        </div>}
                    {value.barrio && value.barrio != '' &&
                        <div className="flex flex-row">
                            <div className="flex-[25%] font-bold mr-5" >Barrio</div>
                            <div className="flex-[75%]">{value.barrio}</div>
                        </div>}
                    {value.tipo == 'LUMINARIA' && <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Artefacto</div>
                        <div className="flex-[75%]">{value?.id_luminaria || '(no indicada)'}</div>
                    </div>}
                    {value.tipo == 'SEMAFORO' && <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Semáforo</div>
                        <div className="flex-[75%]">{value?.id_semaforo || '(no indicado)'}</div>
                    </div>}
                </div>}
            {value.tipo == 'COLECTIVO' &&
                <div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Linea</div>
                        <div className="flex-[75%]">{value?.linea || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Interno</div>
                        <div className="flex-[75%]">{value?.interno || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Hora</div>
                        <div className="flex-[75%]">{value?.evento || '(no indicado)'}</div>
                    </div>
                </div>
            }
            {value.tipo == 'CEMENTERIO' &&
                <div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Cementerio</div>
                        <div className="flex-[75%]">{value?.cementerio || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Sepultura</div>
                        <div className="flex-[75%]">{value?.sepultura || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Sector</div>
                        <div className="flex-[75%]">{value?.sector || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Calle</div>
                        <div className="flex-[75%]">{value?.calleCementerio || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Número</div>
                        <div className="flex-[75%]">{value?.numero || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Fila</div>
                        <div className="flex-[75%]">{value?.fila || '(no indicado)'}</div>
                    </div>
                </div>
            }
            {value.tipo == 'PLAYA' &&
                <div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Playa</div>
                        <div className="flex-[75%]">{value?.playa || '(no indicado)'}</div>
                    </div>
                </div>
            }
            {value.tipo == 'PLAZA' &&
                <div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Plaza</div>
                        <div className="flex-[75%]">{value?.plaza || '(no indicado)'}</div>
                    </div>
                </div>
            }
            {value.tipo == 'ORGAN.PUBLICO' &&
                <div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Organismo</div>
                        <div className="flex-[75%]">{value?.organismo || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Sector</div>
                        <div className="flex-[75%]">{value?.sector || '(no indicado)'}</div>
                    </div>
                </div>
            }
            {value.tipo == 'VILLA' &&
                <div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">NHT</div>
                        <div className="flex-[75%]">{value?.villa || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Manzana</div>
                        <div className="flex-[75%]">{value?.manzana || '(no indicado)'}</div>
                    </div>
                    <div className="flex">
                        <div className="flex-[25%] font-bold mr-5">Casa</div>
                        <div className="flex-[75%]">{value?.casa || '(no indicado)'}</div>
                    </div>
                </div>
            }

        </div>);
}
Direccion.propTypes = {
    value: PropTypes.object.isRequired,
};

const Avances = ({ value: avances }) => {
    return <div>
        <ul className='list-none'>
            {!avances || avances?.length == 0 &&
                <div className='text-center font-bold text-lg'>Sin registro de novedades</div>
            }
            {avances && avances.length > 0 && avances.map((a, ix) => {
                const inicio = new Date(a.tav_tstamp_in);
                const final = a.tav_tstamp_out ? new Date(a.tav_tstamp_out) : null;
                const colorLabel = colorEstado[a.tic_estado_in] ? colorEstado[a.tic_estado_in] : '#000';
                return (
                    <li key={ix} className="w-full mb-5">
                        <div className={`flex border-b w-full ${ix > 0 ? 'border-t' : ''}`}>
                            <div className="flex-[70%] font-bold mr-5 border-r p-2" >{inicio.toLocaleDateString()} {inicio.toLocaleTimeString()}</div>
                            <div className={`flex-[30%] flex items-center justify-center p-2`}>
                                <div className={`text-center text-sm p-2 rounded-xl bg-[${colorLabel}]`}>{a.tic_estado_in}</div>
                            </div>
                        </div>
                        {final && false &&
                            <div>
                                <div>{final.toLocaleDateString()} {final.toLocaleTimeString()}</div>
                                <div>{a.tic_estado_out}</div>
                            </div>}
                        {a.tav_nota != '' &&
                            <div className="w-full p-2">{a.tav_nota}
                            </div>}
                    </li>);
            })}
        </ul>
    </div>;
}
Avances.propTypes = {
    value: PropTypes.array.isRequired,
};

const DetalleReclamo = ({ value: reclamo }) => {
    return (
        <div className="flex flex-row flex-wrap gap-5 bg-gray-50">
            <div className="flex-[100%] lg:flex-[65%]">
                <Avances value={reclamo.avances} />
            </div>

            {reclamo?.lugar?.lng != 0 && reclamo?.lugar?.lat != 0 &&
                <div className="flex-[100%] lg:flex-[30%] overflow-hidden rounded-lg">
                    <Mapa
                        lng={reclamo.lugar.lng}
                        lat={reclamo.lugar.lat}
                        zoomLevel={15}
                        marker={true}
                        onChange={() => { }}
                        elementIcon={reclamo.lugar.tipo}
                    />
                </div>
            }
        </div>);
}
DetalleReclamo.propTypes = {
    value: PropTypes.object.isRequired,
};

const Reclamo = ({ reclamo, index, style, ...other }) => {
    const [vista, setVista] = useState(false);
    const inicio = new Date(reclamo.tic_tstamp_in);
    const prestacion = reclamo.prestaciones[0];
    return (
        <div className="w-full border rounded-lg mb-5 bg-slate-100" {...other}>
            <div className="flex flex-row flex-wrap gap-5">
                <div className="flex-[65%] p-5">
                    <div className="flex flex-row">
                        <div className="font-bold mr-5 text-lg text-[rgb(11,59,108)] mb-5">{prestacion?.tpr_detalle || "Prestación"}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="font-bold mr-5 min-w-[80px]">Nota</div>
                        <div className='flex-1truncate' style={{ overflowWrap: 'anywhere' }}>{reclamo.tic_nota_in}</div>
                    </div>
                    <div className="flex flex-row">
                        <div className="font-bold mr-5 w-[80px]">Ubicación</div>
                        <Direccion value={reclamo.lugar} />
                    </div>
                </div>
                <div className="flex-[30%] p-5 bg-slate-300 rounded-lg">
                    <div className="flex flex-row font-bold">{reclamo.tic_identificador}</div>
                    <div className="flex flex-row">
                        <div className='mr-5 font-bold'>Ingreso</div>{inicio.toLocaleDateString()} {inicio.toLocaleTimeString()}
                    </div>
                    <div className="mt-5 flex flex-row">
                        <Estado className="mr-5 p-3 text-sm rounded" value={reclamo.tic_estado} />
                        {!vista &&
                            <Button variant="contained" onClick={() => setVista(true)} className="p-3">Ver mas</Button>}
                        {vista &&
                            <Button variant="contained" onClick={() => setVista(false)} className="p-3">Ver menos</Button>}
                    </div>
                </div>

                {false && <div className='w-full mt-5'>
                    <div className="encabezado"><b>{reclamo.tic_identificador}</b></div>
                    <div className="flex flex-row"><div className='mr-5 font-bold'>Prestación</div>{prestacion?.tpr_detalle || ""}</div>
                    <div className="flex flex-row"><div className='mr-5 font-bold'>Nota</div>{reclamo.tic_nota_in}</div>
                    <div className="flex flex-row"><div className='mr-5 font-bold'>Ubicación</div><Direccion value={reclamo.lugar} /></div>
                    <div className="flex flex-row"><div className='mr-5 font-bold'>Ingreso</div>{inicio.toLocaleDateString()} {inicio.toLocaleTimeString()}</div>
                    <div className="flex flex-row">
                        <Estado className="mr-5 p-3 text-sm" value={reclamo.tic_estado} />
                        {!vista &&
                            <Button variant="contained" onClick={() => setVista(true)} size="small" className="estado">Ver mas</Button>}
                        {vista &&
                            <Button variant="contained" onClick={() => setVista(false)} size="small" className="estado">Ver menos</Button>}
                    </div>
                </div>}
            </div>

            {vista &&
                <div className="w-full">
                    <DetalleReclamo value={reclamo} />
                </div>}
        </div>
    )
}

Reclamo.propTypes = {
    index: PropTypes.number.isRequired,
    reclamo: PropTypes.object.isRequired,
    style: PropTypes.object,
};

export default Reclamo;
