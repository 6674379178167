import React from 'react';
import PropTypes from 'prop-types';

const Prestaciones = ({list, onClick, value}) => {
  return (
  <div className="flex flex-row gap-5 flex-wrap w-full">
    { list && list.map((l, ix) => 
        <div 
          key={ix} 
          onClick={() => onClick(l)} 
          className={"flex-0 rounded-lg p-5 border hover:bg-gray-100 w-[45%] " + (value.code==l.code ? "border-[rgb(11,59,108)] border-2 bg-gray-100" : "")}>
          <div className='font-bold text-lg'>{l.name}</div>
          <div className='text-sm ml-2'>{l.metadata}</div>
        </div>
      )}
  </div>);
}

Prestaciones.propTypes = {
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default Prestaciones;
