import React from "react";
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import BuscadorLugares from './BuscadorLugares';

const DireccionOrgPublico = ({direccion, onCambiarLugar, onSetText}) => {
    return (<div style={{ flex: "1 1 calc(100% - 10px)", display: "flex", flexWrap: "wrap", gap: "10px", flewWrap: "wrap" }}>
        <BuscadorLugares
            value={direccion?.organismo || ''}
            onChange={onCambiarLugar('organismo')}
            error={false}
            label={'Organismo'}
            type={'ORGAN.PUBLICO'}
        />
        <TextField style={{ flex: "0 0 130px", lineHeight: "60px" }}
            variant="outlined"
            label="Sector"
            value={direccion?.sector}
            onChange={onSetText('sector')}
        />
    </div>)
}

DireccionOrgPublico.propTypes = {
    direccion: PropTypes.object.isRequired,
    onCambiarLugar: PropTypes.func.isRequired,
    onSetText: PropTypes.func.isRequired,
}

export default DireccionOrgPublico;
