/* eslint-disable max-len */
import React, { useEffect } from 'react';
import FormularioDatosPersonales from '../../componentes/FormularioDatosPersonales';
import PropTypes from 'prop-types';
import {HelpCenter} from '@mui/icons-material';
import useUserStore from '../../stores/user.store';
import useAltaReclamoStore from '../../stores/altareclamo.store';

const AltaReclamosPaso4 = ({ 
    options: { 
        setMessageSnackbar, 
    }}) => {

    const { setPersona } = useUserStore();
    const setValidator = useAltaReclamoStore(state => state.setValidator);

    const validator = (ticket) => {
    
        const dniIsCorrect = (dni) => {
            if (dni.value.length == 0) {
                return true;
            }
            const v = parseInt(dni.value);
            if (v.toString().length == dni.value.length) {
                return  !(/\d+/.test(`${v}`));
            } else {
                return true;
            }
        }
    
        const telefonoIsCorrect = (telefono) => {
            if (telefono.value.length == 0) {
                return true;
            }
            const v = parseInt(telefono.value);
            if (v.toString().length == telefono.value.length) {
                return !(/\d+/.test(`${v}`));
            } else {
                return true;
            }
        }
    
        const emailIsCorrect = (email) => {
            if (email.value.length == 0) {
                return true;
            }
            return !(/^[a-z0-9A-Z._-]+@[a-z]+(?:\.[a-z]+)/.test(email.value));
        }
        
        ticket.persona.nombres.error = ticket.persona.nombres.value=='';
        ticket.persona.apellido.error = ticket.persona.apellido.value=='';
        ticket.persona.dni.error = dniIsCorrect(ticket.persona.dni);
        ticket.persona.email.error = emailIsCorrect(ticket.persona.email);
        ticket.persona.telefono.error = telefonoIsCorrect(ticket.persona.telefono);
        
        setPersona(ticket.persona);
        
        if (!ticket.persona.dni.error && !ticket.persona.email.error && !ticket.persona.telefono.error) {
            return true;
        } else {
            setMessageSnackbar('Completa el formulario antes de avanzar', 'error');
        }
        return false;
    }

    useEffect(() => {
        setValidator(4, validator);        
    }, []);

    return (
    <section>
        <div className='font-bold text-3xl text-[rgb(11,59,108)] mb-5'>Ingresá tus datos personales</div>
        
        <div className='w-100 p-5 flex flex-row rounded-lg border-2 align-middle'>
                <div className='flex-0 mr-5'><HelpCenter fontSize='large'/></div>
                <div className='flex-1 text-gray-500'>Complete sus datos personales para que podamos estar en contacto con las novedades de este reclamo. Luego haga click sobre el botón <b>Siguiente</b> al pie de este formulario</div>
        </div>

        <div className='mt-5 mb-5'>
        <FormularioDatosPersonales />
        </div>
    </section>
    );
};

AltaReclamosPaso4.propTypes = {
    options: PropTypes.object.isRequired,
};

export default AltaReclamosPaso4;
