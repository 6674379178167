import React from 'react';
import PropTypes from 'prop-types';
import fileDefault from '../icons/file-blank-solid-240.png';
import filePdf from '../icons/file-pdf-solid-240.png';
import filePng from '../icons/file-png-solid-240.png';
import fileJpg from '../icons/file-jpg-solid-240.png';
import fileDocx from '../icons/file-doc-solid-240.png';


const ImageConfig = ({mime, ...rest}) => {
    const getIcon = (mime) => {
        switch (mime) {
            case 'image/png':
                return filePng;
            case 'image/jpeg':
                return fileJpg;
            case 'application/pdf':
                return filePdf;
            case 'application/docx+xml':
                return fileDocx;
            default:
                return fileDefault;
        }
    }
    return <img src={getIcon(mime)} {...rest}/>;
}

ImageConfig.propTypes = {
    mime: PropTypes.string.isRequired,
}

export default ImageConfig;
