import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAP;

const Mapa = ({ 
  lng, 
  lat, 
  zoomLevel, 
  marker, 
  elementIcon, 
  style, 
  elements, 
  value: direccion, 
  onChange: setDireccion,
  ...rest
 }) => {
  const [listaMarkers, setListaMarkers] = useState();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markerEl = useRef(null);

  // Si el usuario mueve el puntero 
  const moverMarker = (ev) => {
    const coords = ev.target._lngLat
    setDireccion({ latitud: coords.lat, longitud: coords.lng });
  }

  // Direccion del centro del mapa (lugar que puso en calle y altura)
  const createPositionMarker = () => {
    if (markerEl.current) {
      markerEl.current.setLngLat([lng, lat]);
    } else {
      const options = { draggable: true };
      markerEl.current = new mapboxgl.Marker(options)
        .setLngLat([lng, lat])
        .addTo(map.current);
      // Detectar si el usuario mueve el puntero 
      markerEl.current.on('dragend', moverMarker);
    }
  }

  const handleMarkerOnClick = (ev) => {
    const el = ev.target;
    const element = el.element;
    // Reset de cualquier eleccion previa
    document.querySelector('.marker.selected')?.classList?.remove('selected');
    // Resaltar el icono elegido
    el.classList.add('selected');
    // Cambiar el modo a CALLE-ALTURA (por si esta en modo CALLE-CALLE)
    setDireccion({
      elemento: element.id,
      latitud: element.lat * 1,
      longitud: element.lng * 1,
      elementoDescription: `${elementIcon} ${element.id} en ${element.calle} ${element.numero}`,
    });
  }

  // Marker de cada elemento (Semaforo o luminaria)
  const createMarker = (element) => {
    const el = document.createElement('div');
    el.className = 'marker';
    switch (elementIcon) {
      case 'SEMAFORO':
        el.style.backgroundImage = `url(/images/semaforo.png)`;
        break;
      case 'LUMINARIA':
        el.style.backgroundImage = `url(/images/luminaria.png)`;
        break;
      default:
        el.style.backgroundImage = `url(/images/pin.png)`;
        break
    }
    el.style.width = `20px`;
    el.style.height = `54px`;
    el.style.backgroundSize = '100%';
    if (direccion && direccion?.elemento == element.id) {
      // Reset de cualquier eleccion previa
      document.querySelector('.marker.selected')?.classList?.remove('selected');
      // Resaltar el icono elegido
      el.classList.add('selected');
    }
    if (element) {
      el.element = {...element};
      el.addEventListener('click', handleMarkerOnClick);
    }
    return { element: el, offset: [-5, -5] };
  };

  useEffect(() => {
  // Crear el mapa
  if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoomLevel
    });

    // Crear el marker
    if (marker) {
      createPositionMarker();
    }

    // Crear los semaforos/luminarias)
    if (elements?.length > 0) {
      const markers = [];
      elements.map((e) => {
        markers.push(new mapboxgl.Marker(
          createMarker(e))
            .setLngLat([e.lng, e.lat])
            .addTo(map.current));
        e.ready = true;
      });
      setListaMarkers(markers);
    }
  }, []);

  // Si cambian las coordenadas, mover el mapa y el marker
  useEffect(() => {
    if (map.current) {
      map.current.setCenter([lng, lat]);
      // map.current.setZoom(zoomLevel);
      if (marker) {
        createPositionMarker();
      }
    }
  }, [lat, lng]);

  // Si se activa o desactiva el marker
  useEffect(() => {
    if (marker) {
      createPositionMarker();
    } else {
      markerEl?.current?.remove();
      markerEl.current = null;
    }
  }, [marker]);

  // Si cambia el nivel de zoom
  useEffect(() => {
    map?.current?.setZoom(zoomLevel);
  }, [zoomLevel]);

  // Si se agregan elementos fijos
  useEffect(() => {
    if (elements?.length > 0) {
      const markers = listaMarkers ? [...listaMarkers] : [];
      elements.map((e) => {
        if (!e.ready) {
          markers.push(new mapboxgl.Marker(createMarker(e))
            .setLngLat([e.lng, e.lat])
            .addTo(map.current));
          e.ready = true;
        }
      });
      // Si hay elementos nuevos volver a setear la lista de markers
      if(!listaMarkers || markers.length > listaMarkers.length) {
        setListaMarkers(markers);
      }
    }
  }, [elements]);

  return (
    <div style={{ height: '100%', width: '100%', minHeight: '300px', position: 'relative', padding: '0px', margin: '0px', ...style }} {...rest}>
      <div ref={mapContainer} style={{ height: '100%', width: '100%', minHeight: '300px' }} />
    </div>)
}

Mapa.propTypes = {
  lng: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number,
  marker: PropTypes.bool,
  elementIcon: PropTypes.string,
  style: PropTypes.object,
  elements: PropTypes.array,
  value: PropTypes.object,
};

export default Mapa;
