import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../stores/user.store';
import Toast from '../../componentes/Toast';
import Hero from '../../componentes/hero.jsx';
import Footer from '../../componentes/footer.jsx';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
      
const Consultareclamo = () => {
  const navigate = useNavigate();
  const captcha = useRef(null);

  const [message, setMessageSnackbar] = useState('');
  const [severity, setSeverity] = useState('success');
  const [alertId, setAlertId] = useState(0);

  const [captchaValido, cambiarCaptachaValido] = useState(false);

  const dni = useUserStore(state => state.user.dni);
  const setPersona = useUserStore(state => state.setPersona);
  const setReclamo = useUserStore(state => state.setReclamo);
  const setToken = useUserStore(state => state.setToken);
  const reclamo = useUserStore(state => state.reclamo);
  
  const openSnackbar = (msg, sev) => {
    setSeverity(() => sev);
    setMessageSnackbar(() => msg);
    setAlertId((i) => i+1);
  };

  const login = async () => {
    
    if (!captchaValido) {
      openSnackbar('Por favor, completa la verificación reCAPTCHA.', 'error');
      return;
    }

    try {
      let url = new URL(`${api}/login`);
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({ dni: dni.value, reclamo }),
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.status === 200) {
        const data = await response.json();
        if (data.reclamo) {
          setToken(data?.token);
          setReclamo(data?.reclamo);
          navigate(`/reclamos/${reclamo}`);
        } else {
          setPersona({ 
            nombres: {value: data.nombre, error: false}, 
            apellido: {value: data.apellido, error: false}, 
          });
          setToken(data.token);
          navigate('/reclamos/deunapersona');
        }
      } else {
        const {message} = await response.json();
        throw new Error(message);
      }
    } catch (err) {
      openSnackbar(err.message || 'El usuario no fue encontrado', 'error');
    }
  };

  const onChangeCAPTCHA = () => {
    if (captcha.current.getValue()) {
      cambiarCaptachaValido(true);
    } else {
      cambiarCaptachaValido(false);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between h-[100vh]">
        <Hero />

        <div className='mr-5 ml-5 text-center'>
          <div className='font-bold text-3xl text-[rgb(11,59,108)] mb-5'>Consulta el estado de tu solicitud</div>

          <div className='flex flex-col fllex-wrap items-center'>
            
            <div className="flex-1 text-left w-1/2">
              <h4>Buscar por tu número de documento</h4>
              <div
                style={{
                  margin: 'auto',
                  marginBottom: '20px',
                  width: '100%',
                  lineHeight: '70px',
                  display: 'flex',
                  gap: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <TextField
                  label="DNI"
                  variant="outlined"
                  required
                  id="id"
                  style={{ width: '231px', marginTop: '10px' }}
                  value={dni.value}
                  onChange={(ev) => {
                    setPersona({ dni: {value: ev.target.value, error: false} });
                  }}
                />
              </div>

              <h4>Buscar por tu número de reclamo (Número/Año por ejemplo: 1234/2024)</h4>
              <div
                style={{
                  margin: 'auto',
                  marginBottom: '20px',
                  width: '100%',
                  textAlign: 'left',
                  lineHeight: '70px',
                  display: 'flex',
                  gap: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <TextField
                  label="Reclamo"
                  variant="outlined"
                  required
                  id="reclamo"
                  style={{ width: '231px', marginTop: '10px' }}
                  value={reclamo}
                  onChange={(ev) => {
                    setReclamo(ev.target.value);
                  }}
                />
              </div>

              <div style={{ margintTop: '10px' }}>
                {true && <ReCAPTCHA
                  ref={captcha}
                  // eslint-disable-next-line no-undef
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  onChange={onChangeCAPTCHA}
                  hl="es-419"
                />}
              </div>
            </div>

            <div className="flex-1 flex flex-wrap flex-row justify-end mt-5 mb-5" >
              <div style={{ marginRight: '20px' }}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <Button variant="outlined">Cancelar</Button>
                </Link>
              </div>
              <div>
                <Button variant="contained" onClick={login} style={{ background: '#00a6cc' }}>
                  Buscar
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Footer />          
        <Toast message={message} severity={severity} alertId={alertId}/>
      </div>
    </>
  );
};

export default Consultareclamo;
