import React from 'react'

export default function NotFound() {
    return (
        <>
            <h1>La página que está buscando no existe.</h1>
            <a href="/">Ir al inicio</a>
        </>
    );
}
