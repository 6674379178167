import React from 'react';
import { Campaign } from '@mui/icons-material';
import PropTypes from 'prop-types';

const BuscadorPrestacionesItem = ({ item, onClick, ...rest }) => {
    return (
        <div {...rest} className="flex-1 bg-[rgba(200,200,200,.2)] w-full flex flex-col md:flex-row justify-center align-top gap-5 rounded-lg p-5">

            <div className="flex-1">
                <div className="text-lg font-bold">{item.name}</div>
                <div className="text-sm ml-5">{item.metadata}</div>
            </div>

            <div
                className="md:flex-[0 1 200px] h-[40px] bg-[rgb(11,59,108)] p-2 text-white rounded-lg justify-center mt-2 hover:brightness-125"
                onClick={onClick(item.code)}
            >
                <Campaign sx={{ mr: 1 }} /> Iniciá tu reclamo
            </div>

        </div>
    );
}

BuscadorPrestacionesItem.propTypes = {
    item: PropTypes.object,
    onClick: PropTypes.func
}

export default BuscadorPrestacionesItem;
