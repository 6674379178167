import React, {useEffect, useState} from 'react';
import { Snackbar, Slide } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/CancelTwoTone';

function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
}

function Toast({ message, severity, alertId }) {
    const [open, setOpenSnackbar] = useState(false);
    
    useEffect(() => {
        if(message!=='') {
            setOpenSnackbar(true);
            setTimeout(() => setOpenSnackbar(false), 5000);
        }    
    }, [message, alertId]);

    return (
        <Snackbar
            open={open}
            onClose={() => setOpenSnackbar(false)}
            TransitionComponent={SlideTransition}
        >
            <div className={"flex flex-row text-2xl p-5 " + (severity === 'error' ? 'bg-red-500 text-white' : 'bg-green-500 text-white')}>
                <div className="flex-1">{message}</div>
                <div className='cursor-pointer flex-grow-0 ml-5' onClick={() => setOpenSnackbar(false)}><CloseIcon /></div>
            </div>
        </Snackbar>
    );
}

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    severity: PropTypes.string.isRequired,
    alertId: PropTypes.number.isRequired,
};

export default Toast;
