import React from "react";
import PropTypes from 'prop-types';

const RequestElement = ({ label, value, tag }) => {
    return (<div style={{ display: 'flex', padding: '10px', width: '100%', marginTop: '10px' }}>
      <div style={{ fontWeight: 'bold', flex: '0 0 240px' }}>{label}</div>
      <div style={{ flex: '0 0 calc(100% - 240px)' }}>{value}{tag && <><br /><span style={{ fontStyle: 'italic', fontSize: '.8em', color: '#666' }}>{tag}</span></>}</div>
    </div>);
  }
  RequestElement.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    tag: PropTypes.string,
  };

  export default RequestElement;
  