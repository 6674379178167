import React from 'react'
import FooterLogo from '../img/v2/FooterLogo.jpg';

function Footer() {
    return (
        <div className="flex flex-row w-full bg-[#0B3B6C]"  >
            <div className='flex-0 h-16 w-20 sm:h-20 sm:w-32 lg:h-40 lg:w-48 flex flex-row justify-end'>
                <img className="flex-0 lg:h-40" src={FooterLogo}/>
            </div>
            <div className={'flex-1 h-16 sm:h-20 lg:h-40 bg-contain bg-repeat-x'} style={{backgroundImage: "url('/images/v2/FooterStripes.jpg')"}}></div>
        </div>    
    );
}

export default Footer;
