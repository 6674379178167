import React from "react";
import { TextField, FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import BuscadorLugares from './BuscadorLugares';

const DireccionVilla = ({direccion, onCambiarLugar, onSetText}) => {
    return ( <div style={{ flex: "1 1 calc(100% - 10px)", display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <FormControl variant="outlined" sx={{ flex: "0 0 90%", lineHeight: "60px" }}>
            <BuscadorLugares
                value={direccion?.villa || ''}
                onChange={onCambiarLugar('villa')}
                error={false}
                label={'Nucleo habitacional temporal'}
                type={'VILLA'}
            />
            <TextField style={{ flex: "0 0 130px", lineHeight: "60px" }}
                variant="outlined"
                label="Manzana"
                value={direccion?.manzana}
                onChange={onSetText('manzana')}
            />
            <TextField style={{ flex: "0 0 130px", lineHeight: "60px" }}
                variant="outlined"
                label="Casa"
                value={direccion?.casa}
                onChange={onSetText('casa')}
            />
        </FormControl>
    </div>);
}

DireccionVilla.propTypes = {
    direccion: PropTypes.object.isRequired,
    onCambiarLugar: PropTypes.func.isRequired,
    onSetText: PropTypes.func.isRequired,
};

export default DireccionVilla;
