/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ListaPrestaciones from '../../componentes/ListaPrestaciones';
import { HelpCenter } from '@mui/icons-material';
import useTicketStore from '../../stores/ticket.store';
import useAltaReclamoStore from '../../stores/altareclamo.store';

const AltaReclamosPaso1 = ({ 
  options: { 
    setMessageSnackbar,  
  } }) => {

  const error = useTicketStore(state => state.error);
  const {setPrestacion} = useTicketStore();

  const setValidator = useAltaReclamoStore(state => state.setValidator);
  const validator = (ticket) => {
    if (ticket.prestacion && ticket.prestacion.code != '') {
      setPrestacion({ error: false });
      return true;
    } else {
      setPrestacion({ error: true });
      setMessageSnackbar('Debe elegir una prestacion antes de avanzar', 'error');
      return false;
    }
  }

  useEffect(() => {
    setValidator(1, validator);    
  }, [])

  return (
    <div>
      <div className='font-bold text-3xl text-[rgb(11,59,108)] mb-5'>Categorías</div>
      
      <div className='w-100 p-5 flex flex-row rounded-lg border-2 align-middle'>
        <div className='flex-0 mr-5'><HelpCenter fontSize='large' /></div>
        <div className='flex-1'>Seleccione la prestación que mejor describa su reclamo y haga click sobre el botón <b>Siguiente</b> al pie de este formulario</div>
      </div>
      
      <ListaPrestaciones options={{ setMessageSnackbar }} />
      {error && <div className='w-full bg-gray-300 p-3 rounded-lg'>
          En este momento no esta disponible el sistema de reclamos. Reintente mas tarde.<br/>
          <span className='text-red-500'>{error}</span>
      </div>}
    </div>);
};

AltaReclamosPaso1.propTypes = {
  options: PropTypes.object.isRequired,
};

export default AltaReclamosPaso1;
