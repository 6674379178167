import React from 'react';
import PropTypes from 'prop-types';
import {TextField, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, FormLabel, RadioGroup, Radio, FormHelperText} from '@mui/material';
import DropFileInput from './DropFileInput';

const Pregunta = ({
    value, 
    pregunta, 
    onChange, 
    namespace, 
    options: {setMessageSnackbar},
    className,
    ...rest
}) => {
    function handleText(ev) {
        const newValue = ev.target.value;
        onChange({...pregunta, answer: newValue});
    }

    function handleLista(event) {
        const newValue = event.target.value;
        onChange({...pregunta, answer: newValue});
    }

    function handleCheckbox(event) {
        const newValue = event.target.checked ? event.target.value : '';
        onChange({...pregunta, answer: newValue});
    }

    function handleMultiple(value) {
        return (event) => {
            let values = value!='' ? value.split(';') : [];
            if (event.target.checked) {
                values.push(value);
            } else {
                values = values.filter((v) => v!=value);
            }
            const newValue = values.join(';');
            onChange({...pregunta, answer: newValue});
        }
    }

    function handleArchivos(newValue) {
        onChange({...pregunta, answer: newValue});
    }

    return (
    <>
    {pregunta.type=='TEXTO' && 
        <FormControl className={"flex-[100%] lg:flex-[45%] p-2 " + (className ? className : '')} {...rest}>
            <TextField 
                label={pregunta.question} 
                value={value} 
                onChange={handleText}
                helperText={pregunta?.note} 
                disabled={pregunta?.disabled ? true : false}
            />
        </FormControl>}

    {pregunta.type=='LISTA' && (
        <FormControl className={"flex-[100%] lg:flex-[45%] p-2 " + (className ? className : '')} {...rest}>
            <InputLabel htmlFor={`fld${pregunta.code}-select`}>{pregunta.question}</InputLabel>
            <Select
                labelId={`fld${pregunta.code}-label`}
                id={`fld${pregunta.code}-select`}
                value={value}
                label={pregunta.question}
                onChange={handleLista}
            >
                {pregunta.values
                    .split(';')
                    .map((v, ix) => (<MenuItem key={ix} value={v}>{v}</MenuItem>))}
            </Select>
            <FormHelperText>{pregunta?.note}</FormHelperText>
        </FormControl>
    )}

    {pregunta.type=='MULTIPLE' && (
        <FormControl className={"flex-[100%] lg:flex-[45%] p-2 " + (className ? className : '')} {...rest}>
            <FormLabel component="legend">{pregunta.question}</FormLabel>
            <FormGroup helperText={pregunta?.note}>
                {pregunta.values.split(';').map((v, ix) =>(
                    <FormControlLabel 
                        key={ix} 
                        control={<Checkbox checked={value.includes(v)} onChange={handleMultiple(v)}/>} 
                        label={v} 
                    />))}
            </FormGroup>
        </FormControl>
    )}

    {pregunta.type=='CHECKBOX' && (
        <FormControl className={"flex-[100%] lg:flex-[45%] p-2 " + (className ? className : '')} {...rest}>
            <FormLabel id={`fld${pregunta.code}-label`}>{pregunta.question}</FormLabel>
            <RadioGroup
                aria-labelledby={`fld${pregunta.code}-label`}
                value={value}
                name={`fld${pregunta.code}-group`}
                onChange={handleCheckbox}
                helperText={pregunta?.note}
            >
                {pregunta.values.split(';').map((v, ix) => (
                    <FormControlLabel key={ix} value={v} control={<Radio />} label={v} />
                ))}
            </RadioGroup>
        </FormControl>
    )}

    {pregunta.type=='ARCHIVO' && 
        <FormControl className={"flex-[100%] lg:flex-[100%] p-2 " + (className ? className : '')} {...rest}>
            <FormLabel id={`fld${pregunta.code}-label`}>{pregunta.question} {pregunta?.note ? `(${pregunta?.note})` : ''}</FormLabel>
            <DropFileInput 
                namespace={namespace} 
                name={`${pregunta.code}`} 
                value={typeof(value)=='object' ? value : []} 
                options={ {setAttachments: handleArchivos, setMessageSnackbar} } 
                helperText={pregunta?.note}
            />
        </FormControl>}
    </>);
}

Pregunta.propTypes = {
    pregunta: PropTypes.object.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    namespace: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};
  
export default Pregunta;