/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { CircularProgress, Button } from '@mui/material';
import Reclamo from '../../componentes/Reclamo';
import useUserStore from '../../stores/user.store';
import Toast from '../../componentes/Toast';
import axios from 'axios';
import Hero from '../../componentes/hero.jsx';
import Footer from '../../componentes/footer.jsx';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

const Detallesreclamo = () => {
  const [reclamo, setReclamo] = useState();

  const [message, setMessageSnackbar] = useState('');
  const [severity, setSeverity] = useState('error');
  const [alertId, setAlertId] = useState(0);

  const [loading, setLoading] = useState(false);
  const { id, anio } = useParams();
  const token = useUserStore(state => state.token);

  const openSnackbar = (msg, sev) => {
    setSeverity(() => sev);
    setMessageSnackbar(() => msg);
    setAlertId((i) => i + 1);
  };

  const cargaReclamo = () => {
    return new Promise((resolve, reject) => {
      try {
        if (loading) resolve();
        setLoading(true);
        let url = new URL(`${api}/ticket/${id}/${anio}`);
        axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`,
          }
        }).then((response) => {
          if (response.status == 200) {
            const datos = response.data;
            if (datos.message) {
              throw new Error('no se encuentra el reclamo');
            }
            setReclamo(datos.reclamo);
            setLoading(false);
            resolve();
          } else {
            throw new Error('Su sesión ha expirado. Debe identificarse nuevamente.');
          }
        }).catch((err) => {
          openSnackbar(err.message, 'error');
          setLoading(false)
          reject(err);
        })
      } catch (err) {
        openSnackbar(err.message, 'error');
        setLoading(false)
        reject(err);
      }
    });
  }

  useEffect(() => {
    cargaReclamo();
  }, []);

  return (
    <div className="flex flex-col justify-between h-[100vh]">
      
      <div className="flex-0 items-start">
        <Hero />
        <div>
          <div className='text-xl font-bold mt-2 ml-2' >Consulta de un reclamo</div>
          <div className='text-xl mb-2 ml-2'>
            {reclamo ? reclamo.tic_identificador : 'RECLAMO'}
          </div>
        </div>

        <div>
          {reclamo &&
            <Reclamo
              style={{ marginBottom: '20px' }}
              index={1}
              reclamo={reclamo}
            />}

          {!loading && !reclamo &&
            <h2>No se encuentra el reclamo solicitado</h2>}

          {loading &&
            <div style={{ textAlign: "center" }}><CircularProgress style={{ margin: "auto" }} /></div>}
        </div>

        <div className='ml-2'>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button style={{ color: "#666", borderColor: "#666" }} variant="outlined" className='datosreclamo-cancelar-button'>
              Ir al Inicio
            </Button>
          </Link>
          <Link to="/reclamos/consulta" style={{ textDecoration: "none", marginLeft: "20px" }}>
            <Button style={{ background: "#00a6cc" }} variant="contained" className='datosreclamo-cancelar-button'>
              Otra consulta
            </Button>
          </Link>
        </div>
      </div>

      <Footer />
      <Toast message={message} severity={severity} alertId={alertId} />
    </div>
  )
}

export default Detallesreclamo;
