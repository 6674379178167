import React from 'react';
import PropTypes from 'prop-types';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import BuscadorCalles from './BuscadorCalles';

function DireccionDomicilio({ type, direccion, onCambiarModo, onSetCalle, onSetCalleCorta, onSetText, setMessageSnackbar }) {

    return (
        <>
            <div className="flex flex-row flex-wrap gap-5">
                <FormControl className="flex-0 min-w-40" variant="outlined" >
                    <InputLabel id="modo">Modo</InputLabel>
                    <Select
                        labelId="modo"
                        id="modo-select"
                        value={direccion.modo}
                        onChange={onCambiarModo}
                        label="Modo"
                    >
                        <MenuItem value={'CALLE-ALTURA'}>Calle y altura</MenuItem>
                        <MenuItem value={'CALLE-CALLE'}>Cruce de Calle y Calle</MenuItem>
                    </Select>
                </FormControl>

                <BuscadorCalles
                    className="flex-0 w-full lg:w-64 min-w-64"
                    value={direccion.calle}
                    onChange={onSetCalle}
                    label="Calle"
                    error={direccion.error.calle}
                    id="dir-calle"
                />

                {direccion.modo == 'CALLE-ALTURA' &&
                    <TextField
                        className='flex-0 min-w-24'
                        variant="outlined"
                        label="Altura"
                        required
                        error={direccion.error.altura}
                        value={direccion.altura}
                        onChange={onSetText('altura')}
                    />}

                {direccion.modo == 'CALLE-CALLE' &&
                    <BuscadorCalles
                        className="flex-0 w-full lg:w-64 min-w-64"
                        value={direccion.calleCorta}
                        onChange={onSetCalleCorta}
                        label={"Calle que corta"}
                        error={direccion.error.calleCorta}
                        id="dir-calle-corta"
                    />}

                {'LUMINARIA, SEMAFORO'.includes(type) &&
                    <div className='flex flex-wrap items-center'>
                        <TextField
                            className='flex-0 min-w-24 invisible'
                            variant="outlined"
                            label={type}
                            value={direccion.elemento}
                            onChange={() => {
                                setMessageSnackbar(`Para cambiar elija otro ${type} en el mapa`);
                            }}
                            disabled
                        />
                        <div className='text-sm text-grey-600'>
                            {direccion.elementoDescription=='' && <>Seleccione {type=='LUMINARIA' ? 'la luminaria' : 'el semáforo'} en el mapa,<br/> haciendo click sobre el elemento</>}
                            {direccion.elementoDescription!='' && 
                            <div className='bg-green-100 p-2'>
                                {direccion.elementoDescription.split('en')[0]}<br/>{direccion.elementoDescription.split('en')[1]}
                            </div>}
                        </div>
                    </div>
                }

                {type == 'DOMICILIO' && direccion.modo == 'CALLE-ALTURA' &&
                    <TextField
                        className='flex-0 min-w-24'
                        variant="outlined"
                        label="Piso"
                        value={direccion.piso}
                        onChange={onSetText('piso')}
                    />
                }

                {type == 'DOMICILIO' && direccion.modo == 'CALLE-ALTURA' &&
                    <TextField
                        className='flex-0 min-w-24'
                        variant="outlined"
                        label="Departamento"
                        value={direccion.dpto}
                        onChange={onSetText('dpto')}
                    />
                }
                {type == 'DOMICILIO' &&
                    <div className='flex-0 min-w-24'>
                        {direccion.barrio!='' && <>Barrio: {direccion.barrio}<br/></>}
                        {direccion.zonaurbana!='N/A' && direccion.zonaurbana!='' && `Reserva forestal: ${direccion.zonaurbana}`}
                    </div>
                }
            </div>
        </>);
}

DireccionDomicilio.propTypes = {
    type: PropTypes.string.isRequired,
    direccion: PropTypes.object.isRequired,
    onCambiarModo: PropTypes.func.isRequired,
    onSetCalle: PropTypes.func.isRequired,
    onSetCalleCorta: PropTypes.func.isRequired,
    onSetText: PropTypes.func.isRequired,
    setMessageSnackbar: PropTypes.func.isRequired,
};

export default DireccionDomicilio;
