/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable default-case */
/* eslint-disable eqeqeq */
import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useTicketStore from '../stores/ticket.store';
import Prestaciones from './Prestaciones';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV=='development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

const ListaPrestaciones = ({options: {setMessageSnackbar}}) => {
  const [prestaciones, setPrestaciones] = useState();
  const [loading, setLoading] = useState();
  
  const prestacion = useTicketStore(state => state.prestacion);
  const setPrestacion = useTicketStore(state => state.setPrestacion);
  const categorias = useTicketStore(state => state.categorias);
  const categoria = useTicketStore(state => state.prestacion.categoria);
  const setCategoria = useTicketStore(state => state.setCategoria);

  useEffect(() => {
    const load = async (categoriaObj) => {
      try {
        if(loading) {
          return;
        }
        setLoading(true);
        let url = new URL(`${api}/prestacion?output=list&channel=web&filter=code:${categoriaObj.code}&limit=100`);
        const response = await fetch(url, {
          'method': 'GET',
          'mode': 'cors',
          'headers': {'Content-Type': 'application/json'},
        });
        const page = await response.json() 
        setPrestaciones(page.records);
        setLoading(false);
      } catch (err) {
        setPrestaciones(null);
        setMessageSnackbar(err.message);
        setLoading(false);
      }
    };
  
    if (categoria >= 0) {
      load(categorias[categoria]);
    } else {
      setPrestaciones(null);
    }
  }, [categoria]);

  const handleCategoria = (cat) => () => {
    if (categoria == cat) {
      setCategoria(-1);
    } else {
      setCategoria(cat);
    }
  }

  const handlePrestacionClick = (prestacionSelected) => {
    const newPrestacion = {...prestacion, ...prestacionSelected};
    setPrestacion(newPrestacion);
  }
  
  return (
    <>
      <div className="flex flex-row flex-wrap gap-5 justify-center mb-5">
        {prestacion.categoria==-1 && 
        categorias.map((cat, ix) => (
          <div key={ix}>
            <div className="flex-1 max-w-sm" onClick={handleCategoria(ix)}>
              <img src={cat.image}/>
            </div>
          </div>)
        )}

        {categoria>=0 && prestaciones && 
          <>
            <div className="flex flex-row text-2xl w-full mt-5 mb-5 " onClick={handleCategoria(categoria)}>
              <div className="flex-0 mr-5"><ArrowBackIcon/></div> 
              <div className="flex-1">{ categorias[categoria].name }</div>
            </div>
            <Prestaciones list={prestaciones} onClick={handlePrestacionClick} value={prestacion}/>
          </>
        }
      </div>
    </>
  );
};

ListaPrestaciones.propTypes = {
  options: PropTypes.object,
};

export default ListaPrestaciones;
