import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import BuscadorLugares from './BuscadorLugares';

const DireccionCementerio = ({direccion, onCambiarLugar, onSetText}) => {
    return (
        <div style={{ flex: "1 1 calc(100% - 10px)", display: "flex", flexWrap: "wrap", gap: "10px", flewWrap: "wrap" }}>
                    <BuscadorLugares
                        value={direccion?.cementerio || ''}
                        onChange={onCambiarLugar('cementerio')}
                        error={false}
                        label={'Cementerio'}
                        type={'CEMENTERIO'}
                        style={{ flex: "0 0 100%" }}
                    />
                    <TextField style={{ flex: "0 1 140px", lineHeight: "60px" }}
                        variant="outlined"
                        label="Sepultura"
                        value={direccion.sepultura}
                        onChange={onSetText('sepultura')}
                    />
                    <TextField style={{ flex: "0 1 140px", lineHeight: "60px" }}
                        variant="outlined"
                        label="Sector"
                        value={direccion.sector}
                        onChange={onSetText('sector')}
                    />
                    <TextField style={{ flex: "0 1 140px", lineHeight: "60px" }}
                        variant="outlined"
                        label="Calle"
                        value={direccion.calleCementerio}
                        onChange={onSetText('calleCementerio')}
                    />
                    <TextField style={{ flex: "0 1 140px", lineHeight: "60px" }}
                        variant="outlined"
                        label="Número"
                        value={direccion.numero}
                        onChange={onSetText('numero')}
                    />
                    <TextField style={{ flex: "0 0 140px", lineHeight: "60px" }}
                        variant="outlined"
                        label="Fila"
                        value={direccion.fila}
                        onChange={onSetText('fila')}
                    />
                </div>
    );
}   

DireccionCementerio.propTypes = {
    direccion: PropTypes.object.isRequired,
    onCambiarLugar: PropTypes.func.isRequired,
    onSetText: PropTypes.func.isRequired,
}

export default DireccionCementerio;
