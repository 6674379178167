import { create } from 'zustand'
import axios from 'axios';
import VialidadImage from '../img/v2/vialidad.jpg';
import ServiciosUrbanosImage from '../img/v2/servicios-urbanos.jpg';
import PirotecniaImage from '../img/v2/pirotecnia.jpg';
import TransporteImage from '../img/v2/transporte.jpg';
import SeguridadImage from '../img/v2/seguridad.jpg';
import SemaforosImage from '../img/v2/semaforos.jpg';
import DesarrolloSocialImage from '../img/v2/desarrollo-social.jpg';
import BromatologiaImage from '../img/v2/bromatologia.jpg';
import PatrimonialImage from '../img/v2/patrimonio.jpg';
import ArboladoImage from '../img/v2/arbolado.jpg';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

const categorias = [
    {code: '01', name: 'Vialidad y alumbrado', image: VialidadImage}, 
    {code: '03,30,31', name: 'Servicios urbanos', image: ServiciosUrbanosImage}, 
    {code: '21', name: 'Arbolado', image: ArboladoImage}, 
    {code: '23', name: 'Pirotecnia', image: PirotecniaImage}, 
    {code: '08,32', name: 'Transporte', image: TransporteImage}, 
    {code: '07', name: 'Seguridad', image: SeguridadImage}, 
    {code: '04', name: 'Semaforos', image: SemaforosImage}, 
    {code: '29', name: 'Desarrollo social', image: DesarrolloSocialImage}, 
    {code: '09', name: 'Bromatologia', image: BromatologiaImage},
    {code: '16', name: 'Restitución Patrimonial', image: PatrimonialImage},
  ];
  
const ticketModel = {
    nonce: '',
    sent: false,
    description: '',
    utterance: '',
    answers: [],
    ticket: null,
    reCAPTCHA: false,
    error: null,
    questionnaire: [],
    elementos: [],
    categorias: categorias,
    prestacion: {
        error: false,
        code: '',
        geolocation: 'DOMICILIO',
        keywords: '',
        metadata: '',
        name: '',
        resolutionTime: '',
        type: 'RECLAMO',
        attachments: [],
        categoria: -1,
    },
    direccion: {
        calle: {
            codigo: '',
            nombre: ''
        },
        altura: '',
        calleCorta: {
            codigo: '',
            nombre: ''
        },
        // eslint-disable-next-line no-undef
        latitud: (process.env?.REACT_APP_LAT || -38.0175459) * 1,
        // eslint-disable-next-line no-undef
        longitud: (process.env?.REACT_APP_LNG || -57.6705731) * 1,
        elemento: '',
        elementoDescription: '',
        error: {
            calle: false,
            altura: false,
            calleCorta: false,
        },
        modo: 'CALLE-ALTURA',
        piso: '',
        dpto: '',
        barrio: '',
        zonaurbana: '',
        comuna: '',
        nombreFantasia: '',
        zoom: 12,
        playa: '',
        plaza: '',
        lugares: [],
        linea: '',
        interno: '',
        fecha_hora: '',
        villa: '',
        manzana: '',
        casa: '',
        cementerio: '',
        calleCementerio: '',
        sepultura: '',
        sector: '',
        numero: '',
        fila: '',
        organismo: '',
        validada: false,
    }
};

 /**
  * Centrar el mapa en el centro de la ciudad y ajustar el zoom
  */
 const resetCoordsModel = {
    // eslint-disable-next-line no-undef
    latitud: (process.env?.REACT_APP_LAT || -38.0175459) * 1,
    // eslint-disable-next-line no-undef
    longitud: (process.env?.REACT_APP_LNG || -57.6705731) * 1,
    barrio: '',
    zonaurbana: '',
    error: { calle: false, altura: false, calleCorta: false },
    zoom: 15,
    elemento: '', elementoDescription: '',
    playa: '',
    plaza: '',
    lugares: [],
    linea: '', interno: '', fecha_hora: '',
    villa: '', manzana: '', casa: '',
    cementerio: '', calleCementerio: '', sepultura: '', sector: '', numero: '', fila: '',
    organismo: '',
    validada: false,
}

/**
 * eliminar seleccion de calle y altura y elemento seleccionado
 */
const resetDireccionModel = {
    calle: { codigo: '', nombre: '' },
    calleCorta: { codigo: '', nombre: '' },
    altura: '',
    piso: '',
    dpto: '',
    barrio: '',
    comuna: '',
    nombreFantasia: '',
}

const useTicketStore = create((set) => (
    {
        ...ticketModel,

        setSent: (newValue) => set(state => ({ ...state, sent: newValue })),
        setPrestacion: (newValue) => {
            set(state => {
                // buscar la categoria de la prestacion, si el codigo de la prestacion empieza por el codigo de una categoria
                const prestacion = {...state.prestacion, ...newValue, categoria: -1};
                for(let i = 0; i < categorias.length; i++) {
                    const codes = categorias[i].code.split(',');
                    for(let j = 0; j < codes.length; j++) {
                        if(prestacion.code.startsWith(codes[j])) {
                            prestacion.categoria = i;
                            break;
                        }                        
                    }
                    if (prestacion.categoria != -1) break;
                }
                return { 
                    ...state, 
                    prestacion, 
                    questionnaire: [], // al setear una nueva prestacion, borrar el listado de preguntas 
                    elementos: [],  // al setear una nueva prestacion, borrar el listado de elementos
                };
             });
        },
        setCategoria: (newValue) => set(state => ({ ...state, prestacion: {...state.prestacion, categoria: newValue} })),
        setUtterance: (newValue) => set(state => ({ ...state, utterance: newValue} )),
        setElementos: (newValue) => set(state => ({ ...state, elementos: newValue} )),
        setDescription: (newValue) => set(state => ({ ...state, description: newValue })),
        setAnswers: (newValue) => set(state => ({ ...state, answers: newValue })),
        setDireccion: (newValue) => set(state => ({ ...state, direccion: {...state.direccion, ...newValue }})),
        setTicket: (newValue) => set(state => ({ ...state, ticket: newValue, sent: true })),
        setReCAPTCHA: (newValue) => set(state => ({ ...state, reCAPTCHA: newValue })),
        reset: () => {
            return new Promise((resolve, reject) => {
                let url = new URL(`${api}/ticket/initialize`);
                axios.get(url)
                .then(({data: nonce}) => {
                    const newState = { ...ticketModel, nonce };
                    // quiero guardar el valor actual de utterance en el nuevo reclamo
                    set((state) => ({...newState, utterance: state.utterance}));
                    resolve();
                })
                .catch((err) => {
                    set((state) => ({...state, error: err.message}));
                    console.log(err);
                    reject(err);
                });
            });
        },
        resetCoords: () => set(state => ({ ...state, direccion: {...state.direccion, ...resetCoordsModel} })),
        resetDireccion: () => set(state => {
            const newState = { 
                ...state, 
                direccion: {
                    ...state.direccion, 
                    ...resetDireccionModel, 
                    ...resetCoordsModel, 
                    validada: false,
                    questionnaire: [], 
                    elementos: []
                } 
            };
            return newState;
        }),
        setQuestionnaire: (newValue) => set(state => ({ ...state, questionnaire: newValue || []} )),
        setAttachments: (newValue) => set(state => ({ ...state, prestacion: {...state.prestacion, attachments: newValue} })),
    }))

export default useTicketStore;
