import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ImageConfig from './ImageConfig';
import uploadImg from '../icons/cloud-upload-regular-240.png';
import TrashIcon from '@heroicons/react/16/solid/TrashIcon.js';
import { getDroppedOrSelectedFiles } from './html5-file-selector';

//import 'react-dropzone-uploader/dist/styles.css'
//import Dropzone from 'react-dropzone-uploader'

/**
 * TODO: UTILIZAR EL COMPONENETE DE REACT-DROPZONE 
 * LOS EVENTOS DE onDrop NO FUNCIONAN PARA SOLTAR ARCHIVOS DESDE EL FINDER
 *  value = [{name, nameBackend}]
 * Para separar los uploads en el backend, se manda un ID unico que se incluye en el setAttachments()
 */

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

const DropFileInput = ({ 
    namespace, 
    name, 
    value, 
    options: { setAttachments, setMessageSnackbar },
}) => {
    const inputRef = useRef();
    const dropzoneRef = useRef();

    useEffect(() => {
        // lista de archivos ya subidos
    }, [value]);

    // specify upload params and url for your files
    const getUploadUrl = () => {
        const url = new URL(`${api}/uploadFile/${namespace}/${name}`);
        return url;
    }

    const addFiles = (files) => {
        //Upload files
        files.map((file, key) => {
            file.key = key;
            const form = new FormData();
            form.append('file', file.fileObject);
            form.append('key', file.key);

            fetch(getUploadUrl(), {
                method: 'POST',
                body: form, 
            })
                .then((r) => r.json())
                .then((res) => {
                    if (res.statusCode && res?.statusCode !== 200) {
                        console.log(res?.message);
                        setMessageSnackbar('Error al cargar el archivo', 'error');
                        return;    
                    }
                    const {fileNames} = res;
                    file.nameBackend = fileNames[0];
                    const newFiles = value ? [...value, file] : [file];
                    setAttachments(newFiles);

                    setMessageSnackbar(`Archivo subido ${file.name}`, 'success');
                })
                .catch((err) => {
                    console.log(err);
                    setMessageSnackbar('Error al cargar el archivo', 'error');
                });
            });
    };

    const handleAdd = (e) => {
        e.preventDefault();
        getDroppedOrSelectedFiles(e)
            .then((files) => {
                addFiles(files);
                dropzoneRef.current?.classList.remove('bg-gray-200');
            })
            .catch(() => {
                dropzoneRef.current?.classList.remove('bg-gray-200');
            });
    }

    return (
        <div className='flex flex-col lg:flex-row gap-5'>
            <div className="flex-[100%] lg:flex-[45%] rounded-lg border-2 p-5 flex items-center justify-center"
                ref={dropzoneRef}
                onDrop={handleAdd}
                onDragOver={e => {
                    e.preventDefault();
                    dropzoneRef.current.classList.add('bg-gray-200');
                }}
                onDragLeave={e => {
                    e.preventDefault();
                    dropzoneRef.current.classList.remove('bg-gray-200');
                }}
                onClick={() => inputRef.current.click()}
            >
                <img src={uploadImg} className='w-10 mr-5'/> Arrastre los archivos aqui o haga click para cargar
                <input type="file" multiple onChange={handleAdd} className='hidden' ref={inputRef} />
            </div>

            <div className='flex-[100%] lg:flex-[45%] flex flex-wrap gap-5'>
                {typeof(value) === 'object' && 
                    value?.filter((v) => v?.nameBackend?.startsWith(`${namespace}-${name}-`))
                        .map((file, index) => (
                    <div className="flex-0 flex mt-2 mb-2 mr-2 p-5 rounded-lg border-2 align-middle bg-white" key={index} >
                        <ImageConfig mime={file.type} className="w-10 h-10" />
                        <div className='text-gray-500'>{file.name}</div>
                        <a className='cursor-pointer ml-5 border p-2' title="Eliminar">
                            <TrashIcon className='w-4 h-4' onClick={() => {
                                const newFiles = [...value.slice(0, index), ...value.slice(index + 1)];
                                setAttachments(newFiles);
                            }} />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func,
    value: PropTypes.array,
    options: PropTypes.object.isRequired,
    namespace: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    helperText: PropTypes.string,
}

export default DropFileInput;