/* eslint-disable react/prop-types */
import React from 'react';
import { TextField, Box } from '@mui/material';
import { Fecha } from './Fecha';
import useUserStore from '../stores/user.store';

/**
 * @return {object}
 */
const FormularioDatosPersonales = ({...other}) => {
  const user = useUserStore(state => state);
  const persona = user.user;
  if (!persona) return (<div></div>);
  
  const handleUpdate = (field) => (event) => {
    const newValue = {...persona,[field]: {...persona[field], value: event.target.value}};
    user.setPersona(newValue);
  }

  return ( 
    <Box style={{display: 'flex', flexWrap: 'wrap', gap: '20px'}} {...other}>
      <TextField
        style={{flex: '0 0 calc(50% - 20px)'}}
        value={persona?.nombres?.value}
        error={persona?.nombres?.error}
        label="Nombre"
        onChange={handleUpdate('nombres')}
      />

      <TextField
        style={{flex: '0 0 calc(50% - 20px)'}}
        value={persona?.apellido?.value}
        error={persona?.apellido?.error}
        label="Apellido"
        onChange={handleUpdate('apellido')}
      />

      <TextField
        style={{flex: '0 0 calc(50% - 20px)'}}
        required
        value={persona?.dni?.value}
        error={persona?.dni?.error}
        label="DNI"
        onChange={handleUpdate('dni')}
      />
            
      <TextField
        style={{flex: '0 0 calc(50% - 20px)'}}
        error={persona?.email?.error}
        required
        value={persona?.email?.value}
        label="Email"
        onChange={handleUpdate('email')}
      />

      <TextField
        style={{flex: '0 0 calc(50% - 20px)'}}
        required
        value={persona?.telefono?.value}
        error={persona?.telefono?.error}
        label="Telefono"
        onChange={handleUpdate('telefono')}
      />
    </Box>
  );
}

export default FormularioDatosPersonales;
